.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cursor-pointer{
  cursor: pointer;
}
.bg-green{
  background-color: rgb(0, 55, 19);
}
.card-body .logo{
  margin-top: -80px;
}
.btn-primary {
    color: #fff;
    background-color: rgb(0, 55, 19) !important;
    border-color: rgb(0, 56, 19) !important;
}

.bg-green {
  background-color: rgb(0, 55, 19) !important;
}

.page-item.active .page-link {
  color: #fff;
  background-color: rgb(0, 55, 19) !important;
  border-color: rgb(0, 55, 16) !important;
}
td a {
    color: rgb(0, 55, 16) !important;
}

.bg-primary2 {
    background-color: rgb(0, 55, 19) !important;
}

.crop-container{
}
.crop{
  min-height: 400px;
}

.inputfile{
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  z-index: 100;
	cursor: pointer;
}
